import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar.tsx";
import Carousel from "../common components/Carousel.tsx";
import {
  HappyCustomer,
  HomeImage,
  HomeImage2,
  HomeImage3,
  Prize,
  Rating,
  cities,
} from "../../Assets.tsx";
import Footer from "../Footer/Footer.tsx";
import Carousel2 from "../common components/Carousel2.tsx";
import WhatsAppButton from "../common components/WhatsAppButton.tsx";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PestControl from "./PestControl.tsx";
import InvisibleGrill from "./InvisibleGrill.tsx";
import SlidingWindow from "./SlidingWindow.tsx";
import GallerySection from "./GallerySection.tsx";
import TestinomialsSection from "./TestinomialsSection.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Homepage = () => {
  const images = [HomeImage, HomeImage2, HomeImage3];

  const overlayText =
    "We are the experts in pest control, invisible grill, pigeon net, sliding window & fabrication and other services  ";
  const handleContactClick = () => {
    window.location.href = "tel:9822664095";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:gspuneoffice@gmail.com";
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
        gsap.from(".GsapCustomerSatisfaction", {
            opacity: 0,
            duration: 2,
            x: -500,
            scrollTrigger: {
              trigger: ".GsapCustomerSatisfaction",
              scroller: "body",
              markers: false,
              start: "top 50%",
            },
          });
    }
  });

  return (
    <div>
      <Navbar />
      <div>
        <Carousel images={images} text={overlayText} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: "wrap",
            background: "#fafafa",
            padding: "20px 0",
          }}
          className="ActionButton"
        >
          <button
            className="Buttons ContactUsButton"
            style={{
              padding: "25px",
              background: "#0092cb",
              color: "white",
              fontSize: "20px",
              width: "240px",
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              justifyContent: "space-evenly",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleContactClick}
          >
            <PhoneIcon style={{ fontSize: "30px", marginRight: "10px" }} /> Call
            Us
          </button>

          <div className="ContactUsButton">
            <WhatsAppButton phoneNumber="+918080958182" />
          </div>
          <button
            style={{
              padding: "20px",
              background: "#0092cb",
              color: "white",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              cursor: "pointer",
              width: "250px",
            }}
            className="Buttons "
            onClick={handleEmailClick}
          >
            <div style={{ display: "flex" }}>
              <EmailIcon style={{ fontSize: "40px", marginRight: "10px" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "16px", marginBottom: "5px" }}>
                Email Us
              </span>
              <span style={{ fontSize: "14px" }}>gspuneoffice@gmail.com</span>
            </div>
          </button>
        </div>
      </div>
      <PestControl />
      <InvisibleGrill />
      <SlidingWindow />
      <GallerySection />
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="GsapCustomerSatisfaction"
      >
        <div
          style={{
            width: "70%",
            height: "auto",
            borderRadius: "25px",
            border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "40px",
            marginBottom: "10px",
          }}
          className="customerDiv"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="customSection"
          >
            <img src={HappyCustomer} style={{ width: "80px" }} />
            <span style={{ fontSize: "18px", fontWeight: 700 }}>10k+</span>
            <span style={{ textTransform: "capitalize" }}>
              Happy Customers service
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="customSection"
          >
            <img src={Rating} style={{ width: "80px" }} />
            <span style={{ fontSize: "18px" }}>
              <span style={{ fontWeight: 700 }}>04/05</span>
              <span style={{ marginLeft: "4px" }}>Rating and</span>
            </span>
            <span>Review from Clients</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="customSection"
          >
            <img src={cities} style={{ width: "80px" }} />
            <span style={{ fontSize: "18px", fontWeight: 700 }}>10+Cities</span>
            <span>Presence Across India</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="customSection"
          >
            <img src={Prize} style={{ width: "80px" }} />
            <span>Warranty Backed Service</span>
          </div>
        </div>
      </div>
      {/* Our valueable clients */}
      <div>
        <Carousel2 />
      </div>
      <TestinomialsSection />
      <Footer />
    </div>
  );
};

export default Homepage;
