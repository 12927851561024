import React from "react";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Card = ({ image, name, description, route }) => {
  const history = useHistory();

  const handleMoreClick = () => {
    history.push(route);
  };

  const truncateName = (name) => {
    const maxLength = 14;
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <div style={styles.card} className="CardSizes ">
      <img src={image} alt={name} style={styles.image} onClick={handleMoreClick} />
      <div
        style={{ display: "flex", flexDirection: "column", margin: "0 20px" }}
        className="PestControlTextContainer"
      >
        <div style={{...styles.name,textTransform:"capitalize"}}>
          <h3>{name}</h3>
        </div>

        <div>
          <p style={{ textTransform: "capitalize" }} className="PestControlDescription">{description}</p>
        </div>
        <button onClick={handleMoreClick} className="PestControlViewMoreButton HoverButtons" style={styles.button}>
          Read More
        </button>
      </div>
    </div>
  );
};

const styles = {
  card: {
    border: "1px solid #ddd",
    borderRadius: "15px",
    margin: "6px 6px 10px 6px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "315px",
  },
  image: {
    backgroundPosition: "50% 50%",
    width: "100%",
    borderRadius: "15px 15px 0px 0px ",
    cursor: "pointer",
  },
  name: {
    marginTop: "12px",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: "#0092cb",
  },
  button: {
    color: "#0092cb",
    border: "1px solid #0092cb",
    width: "150px",
    padding: "10px 30px",
    marginTop: "20px",
    marginBottom: "10px",
    background: "white",
    cursor: "pointer",
    borderRadius: "7px",
  },
};

export default Card;
