import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Aditya,
  Atum,
  Axis,
  Axiverse,
  Baro,
  CC,
  COEP,
  Forest,
  Jp,
  Regus,
  Shangrila,
  kakaHalwai,
  mahabaleshwar,
  milk,
  mumbai,
  rio,
  sarwataTech,
  wipro,
} from "../../Assets.tsx";
import './Carousel.css';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface Item {
  image: string;
  name: string;
}

const insects: Item[] = [
  { image: wipro, name: "Wipro" },
  { image: kakaHalwai, name: "kaka halwai" },
  { image: rio, name: "Rio Energy Drinks" },
  { image: Axis, name: "Axis Bank" },
  { image: Atum, name: "Atum Informartion Technologies" },
  { image: sarwataTech, name: "Sarwarta technologies" },
  { image: mumbai, name: "sakhar sankul mumbai" },
  { image: milk, name: "sakas milk patan" },
  { image: Regus, name: "Regus business centre" },
  { image: Jp, name: "JP Naik Centre for education and development" },
  { image: Shangrila, name: "Hotel Shangrila" },
  { image: COEP, name: "COEPEnviro baner" },
  { image: CC, name: "Cc & Company" },
  {image:Aditya,name:"Aditya Packaging & Consulting Services Pvt. Ltd"},
  {image:Baro,name:"Hotel Barometer"},
  {image:Forest,name:"Forest Escapes Koyna Resort"},
  {image:mahabaleshwar,name:"Hotel Mahabaleshwar"},
  {image:Axiverse,name:"Axiverse Management Solutions Llp"}

];

const Carousel2: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [items, setItems] = useState<Item[]>(insects);
  const [items2, setItems2] = useState<Item[]>(insects);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapClientssection", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".GsapClientssection ",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
        gsap.from(".GsapCarousel", {
            opacity: 0,
            duration: 2,
            x: -500,
            scrollTrigger: {
              trigger: ".GsapCarousel",
              scroller: "body",
              markers: false,
              start: "top 50%",
            },
          });
    }
  });
  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 7000); 
    return () => clearInterval(timer); 
  }, [currentSlide]);
  
  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide2();
    }, 5000); 
    return () => clearInterval(timer); 
  }, [currentSlide2]);
  const itemsPerSlide = 4;
  const totalSlides = Math.ceil(items.length / itemsPerSlide);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const nextSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide + 1) % totalSlides2);
  };

  const prevSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide - 1 + totalSlides2) % totalSlides2);
  };

  const getSlideItems = () => {
    const startIndex = currentSlide * itemsPerSlide;
    return items.slice(startIndex, startIndex + itemsPerSlide);
  };
  const itemsPerSlide2 = 1;
  const totalSlides2 = Math.ceil(items2.length / itemsPerSlide2);
  const getSlideItems2 = () => {
    const startIndex2 = currentSlide2 * itemsPerSlide2;
    return items2.slice(startIndex2, startIndex2 + itemsPerSlide2);
  };


  return (
    <div className="carousel-container5 GsapCarousel">
      <h1 style={{ textAlign: "center", color: "#0092cb", textTransform: "capitalize",fontSize:"40px" }} className='GsapClientssection'>
        Our Valuable Clients
      </h1>
      <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      
      <ArrowBackIosIcon onClick={prevSlide} style={{cursor:"pointer",fontSize:"30px",color:"#0092cb"}}  className='items' />
      <ArrowBackIosIcon onClick={prevSlide2} style={{cursor:"pointer",fontSize:"30px",color:"#0092cb"}} className='humburger-menu'  />

      <div className="carousel5 items">
        {getSlideItems().map((item, index) => (
          <div key={index} className="carousel-item5">
            <img src={item.image} alt={item.name} className="carousel-image5" />
            <h2 style={{ color: "#0092cb", textTransform: "capitalize" }}>{item.name}</h2>
          </div>
        ))}
      </div>
      <div className="carousel5 humburger-menu">
        {getSlideItems2().map((item, index) => (
          <div key={index} className="carousel-item5">
            <img src={item.image} alt={item.name} className="carousel-image5" />
            <h2 style={{ color: "#0092cb", textTransform: "capitalize" }}>{item.name}</h2>
          </div>
        ))}
      </div>
      <ArrowForwardIosIcon onClick={nextSlide2} style={{cursor:"pointer",fontSize:"30px",color:"#0092cb"}} className='humburger-menu' />

      <ArrowForwardIosIcon onClick={nextSlide} style={{cursor:"pointer",fontSize:"30px",color:"#0092cb"}} className='items' />
      </div>
    </div>
  );
};

export default Carousel2;
