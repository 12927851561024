import React from 'react';
import Slider from 'react-slick';
import './Testimonials.css'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Testimonials = ({ reviews }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="slider-container">
      <a target='_blank' href="https://www.google.com/search?q=GS+ENterprises+Gopika+bunglow+Taware+colony&rlz=1C1UEAD_enIN1001IN1001&oq=GS+ENterprises+Gopika+bunglow+Taware+colony&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCTE0MTM2ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bc2c10040b21c99:0x47830287aa1e4d45,1,,,," style={{textDecoration:"none",color:"black"}}>

      <Slider {...settings}>
        {reviews.map((review, index) => (
       
          <div key={index} className="testimonial">
            <div className="testimonial-card">
              <div className="testimonial-header">
                <img src={review.userImage} alt={review.userName} className="user-image" />
                <div className="user-info">
                  <p>{review.userName}</p>
                  <span style={{fontSize:"14px",fontWeight:600,textTransform:"capitalize"}}>
                    {review.userInformation}
                    </span>
                  <div className="user-rating">
                    {Array(review.rating).fill('\u2605').map((star, i) => (
                      <span key={i} style={{width:"15px"}}>{star}</span>
                    ))}
                  </div>
                </div>
              </div>
              <p className="testimonial-text">{review.text}</p>
            </div>
          </div>

         
        ))}
      </Slider>
      </a>
    </div>
  );
};

export default Testimonials;