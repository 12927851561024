import React from "react";
import Navbar from "../Navbar/Navbar.tsx";
import Footer from "../Footer/Footer.tsx";
import {
  GalleryImages10,
  GalleryImages11,
  GalleryImages12,
  GalleryImages13,
  GalleryImages14,
  GalleryImages15,
  GalleryImages16,
  GalleryImages17,
  GalleryImages18,
  GalleryImages19,
  GalleryImages20,
  GalleryImages21,
  GalleryImages22,
  GalleryImages23,
  GalleryImages24,
  GalleryImages25,
  GalleryImages26,
  GalleryImages27,
  GalleryImages28,
  GalleryImages29,
  GalleryImages3,
  GalleryImages30,
  GalleryImages31,
  GalleryImages32,
  GalleryImages33,
  GalleryImages34,
  GalleryImages35,
  GalleryImages36,
  GalleryImages37,
  GalleryImages38,
  GalleryImages39,
  GalleryImages4,
  GalleryImages5,
  GalleryImages6,
  GalleryImages7,
  GalleryImages8,
  GalleryImages9,
  
  InvisiblegrillBanner,
} from "../../Assets.tsx";
import "../../ants.css";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Gallery = () => {
  const galleryImages = [
    GalleryImages27,
    GalleryImages28,
    GalleryImages29,
    GalleryImages30,
    GalleryImages31,
    GalleryImages32,
    GalleryImages33,
    GalleryImages34,
    GalleryImages35,
    GalleryImages36,
    GalleryImages37,
    GalleryImages38,
    GalleryImages39,
    GalleryImages3,
    GalleryImages4,
    GalleryImages5,
    GalleryImages6,
    GalleryImages7,
    GalleryImages10,
    GalleryImages11,
    GalleryImages12,
    GalleryImages13,
    GalleryImages14,
    GalleryImages15,
    GalleryImages16,
    GalleryImages17,
    GalleryImages18,
    GalleryImages19,
    GalleryImages20,
    GalleryImages21,
    GalleryImages22,
    GalleryImages23,
    GalleryImages24,
    GalleryImages25,
    GalleryImages26,

  ];
  const history = useHistory();

  useGSAP(() => {
    gsap.from(".gsapBannerGalleryPage" , {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".carousel__text", {
      opacity: 0,
      duration: 2,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".carousel__text ",
        scroller: "body",
        markers: false,
        start: "top 55%",
      },
    });
  });
  
  useGSAP(() => {
    gsap.from(".GsapGallery", {
      opacity: 0,
      duration: 2,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".GsapGallery",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
  });

  return (
    <div>
      <Navbar />
      <div style={{ position: "relative" }}>
        <img
          src={InvisiblegrillBanner}
          style={{ width: "100%", height: "auto" }}
          className="BannerImage gsapBannerGalleryPage"
        />
        <div className="carousel__text">
        <a
            href="#Gallery"
            className="options"
            style={{ color: "black", textDecoration: "none" }}
            onClick={() => {
              history.push("/");
            }}
          >
            <button
              style={{
                display: "flex",
                alignSelf: "start",
                color: "white",
                backgroundColor: "#0092cb",
                border: "none",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              className="Buttons"
            >
              Back
            </button>
          </a>
          <div className="carousel__text-container">
            <p style={{ textTransform: "capitalize",fontSize:"50px" }}>
              Gallery
            </p>
          </div>
        </div>
      </div>
      
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {galleryImages.map((item, index) => (
          <div style={{ margin: "5px 5px 0 0" }}>
            <img
              key={index}
              src={item}
              alt={`Gallery Image ${index + 1}`}
              style={{ width: "300px", height: "400px" }}
              className="GsapGallery"
            />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
