import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { WhatsApp } from "../../Assets.tsx";
interface WhatsAppButtonProps {
  phoneNumber: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <div
      onClick={handleWhatsAppClick}
      className="Buttons"
      style={{
        cursor: "pointer",

        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        padding: "5px 10px",
        border: "1px solid white",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        width:"220px"
      }}
    >
      <img src={WhatsApp} style={{ width: "80px" }} />
      WhatsApp us
    </div>
  );
};

export default WhatsAppButton;
