import React, { useEffect, useState } from 'react'
import Testimonials from '../common components/Testinomials.tsx'
import { female, male } from '../../Assets.tsx';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TestinomialsSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapTestinomails ", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".GsapTestinomails ",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
    const reviews = [
        {
          userName: "Harshal",
          userInformation: "frontend developer, Atum technologies",
          userImage: male,
          rating: 5,
          text: "Excellent service and great quality food. Highly recommend!",
        },
        {
          userName: "Pratiksha",
          userImage: female,
          userInformation: "fullstack developer, Atum technologies",
          rating: 4,
          text: "Good atmosphere and friendly staff. The food was delicious.",
        },
        {
          userName: "Atul",
          userInformation: "CEO, Atum Technologies",
          userImage: male,
          rating: 5,
          text: "Amazing experience! Will definitely come back again.",
        },
        {
          userName: "Faisal",
          userInformation: "frontend developer, Atum technologies",
          userImage: male,
          rating: 5,
          text: "Amazing experience! Will definitely come back again.",
        },
      ];
  return (
    <div>
         <h1
        style={{
          textAlign: "center",
          color: "#0092cb",
          textTransform: "capitalize",
          fontSize: "40px",
        }}
        className='GsapTestinomails'
      >
        our Testimonials
      </h1>
      <div>
        <Testimonials reviews={reviews} />
      </div>
      
    </div>
  )
}

export default TestinomialsSection
