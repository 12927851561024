import React from "react";
import Navbar from "../Navbar/Navbar.tsx";
import { Aboutus } from "../../Assets.tsx";
import Footer from "../Footer/Footer.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutsusPage = () => {

   
  useGSAP(() => {
    gsap.from(".gsapBannerAbouUsImage" , {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  return (
    <div>
      <Navbar />
      <img src={Aboutus} style={{ width: "100%", height: "auto",paddingTop:"50px" }} className="gsapBannerAbouUsImage" />
      <div style={{margin:"10px 200px"}} className="aboutUsContainer">
        <h2 style={{ textTransform: "capitalize",color:"#0092cb" }}>about us </h2>

        <h3 style={{color:"#0092cb"}}>India's Hygiene Expert</h3>
        <p>
          Gs Enterprises is a Digital, Responsible, and Hygiene company for Complete
          Home and Institutional solution in India. As a national brand with a
          legacy of three decades, we have served over 30 lakh homes and 300+
          corporates.
        </p>

        <h3 style={{color:"#0092cb"}}>DIGITAL HYGIENE</h3>
        <p>
          We love to set benchmarks in digital hygiene by offering hassle-free
          and 24x7 online transparency. We follow one-nation-one-price for
          almost all our services and all our service protocols, scheduling, the
          intimation of service, audit reports, infestation trends and billing
          details are done in a paperless and robust ecosystem, online.
        </p>
        <h3 style={{color:"#0092cb"}}>RESPONSIBLE HYGIENE</h3>
        <p>
          As India’s leading hygiene brand, we place a great onus on taking care
          of the environment, our customers and our Hygiene Experts.
        </p>
        <ul>
          <li>
            Gs Enterprises is India's only HACCP (Hazard Analysis Critical Control
            Point) Certified pest service provider
          </li>
          <li>
            We use innovative and scientific solutions to minimize chemical
            usage for safe, odourless, and long-lasting effectiveness
          </li>
          <li>
            Our chemicals are approved by Central Insecticides Board and follow
            government regulations and licensing control, this means the potency
            is safe for children, pets and elders
          </li>
          <li>
            We do not use a third party or contractors, and each of our Hygiene
            Expert who comes to your home or premise, is background verified,
            trustworthy and a well-trained employee
          </li>
          <li>
            During this pandemic we have initiated 3-levels of Kavach for our
            Hygiene Experts – Aarogya Setu App, Temperature Check and Silver
            Nano Disinfection on daily basis
          </li>
        </ul>
        <h3 style={{color:"#0092cb"}}>Comprehensive Hygiene Solutions</h3>
        <p>
          We are truly India’s one-stop-destination for hygiene and pest control
          – namely, pest control, disinfection and cleaning.
        </p>
        <h3 style={{color:"#0092cb"}}>PEST CONTROL SERVICES</h3>
        <p>
          Gs Enterprises is India's leading and most trusted service provider for
          residential and commercial pest control.
        </p>
        <ul>
          <li>3X Cockroach Control (and Ant Control)</li>
          <li>3X Bedbug Control</li>
          <li>3X Mosquito Control</li>
          <li>Fly Control</li>
          <li>Rats & Mice Control</li>
          <li>3X Termite & Woodborers Control</li>
          <li>Bird Netting for Pigeons and Sparrows</li>
        </ul>
        <h3 style={{color:"#0092cb"}}>DISINFECTION SERVICES</h3>
        <p>
          Even before the pandemic, Gs Enterprises was at the forefront of disinfection
          services for home, offices and commercial premises. We offer
          disinfection in four formats depending upon the need.
        </p>
        <ul>
          <li>Water-based Disinfection</li>
          <li>Steam-based Disinfection</li>
          <li>Alcohol-based Disinfection</li>
          <li>Herbal-based Disinfection – Silver Nano</li>
        </ul>
        <p>
          For instance, we are the first Indian company to offer Silver Nano for
          99.99% disinfection from the Corona virus. The same has certified by
          Microchem Lab (US EPA & FDA compliant).
        </p>
        <h3 style={{color:"#0092cb"}}>We Are Proud to make India Proud</h3>
        <ul>
          <li>
            When India was reeling under mosquito-caused epidemic in 2019 with
            over 1 lakh cases of Dengue, Malaria, Chikungunya, we responded by
            launching India's first anti-dengue and anti-malaria pest control
            (targeted service)
          </li>
          <li>
            First in India to add mosquito repellent garbage bags and a patented
            drain-cleaning enzyme to fight cockroach menace
          </li>
          <li>
            First to introduce wall-traps for mosquitoes using micro-suspension
            capsule spray on walls
          </li>
          <li>
            First in India to use safe and herbal Silver Nano spray for
            disinfection of homes and premises against Corona virus
          </li>
          <li>
            First in India to establish Pest Control Services Training Centre in
            Bangalore in 2016 with in-depth classroom sessions and live demos
          </li>
        </ul>
        
      </div>
      <Footer />
    </div>
  );
};

export default AboutsusPage;
