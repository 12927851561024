import React, { useEffect, useState } from 'react'
import { GalleryImages28, Gallery1, GalleryImages39, GalleryImages14, gallery5 } from '../../Assets.tsx';
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const GallerySection = () => {
    const history = useHistory();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    useGSAP(() => {
      if (!isMobile) {
        gsap.from(".GsapGallerysection", {
          opacity: 0,
          duration: 1,
          x: 500,
          scrollTrigger: {
            trigger: ".GsapGallerysection ",
            scroller: "body",
            markers: false,
            start: "top 55%",
          },
        });
      }
    });
    // useGSAP(() => {
    //   if (!isMobile) {
    //   gsap.from(".gsapGalleryImages", {
    //     opacity: 0,
    //     duration: 1,
    //     scale: 0.5,
    //     y: 20,
    //     delay: 1,
    //     scrollTrigger: {
    //       trigger: ".gsapGalleryImages ",
    //       scroller: "body",
    //       markers: false,
    //       start: "top 55%",
    //     },
    //   });
    // }
    // });
  return (
    <div
    style={{ width: "100%", height: "auto", marginBottom: "20px" }}
    id="Gallery"
  >
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#0092cb",
          textTransform: "capitalize",
          fontSize: "40px",
        }}
        className='GsapGallerysection'
      >
        Gallery
      </h1>

      <div style={{ display: "flex" }} className="fabricationSection">
        <img
          src={GalleryImages28}
          style={{ width: "650px" }}
          className="fabricationBanner"
        />
        <div
          style={{ display: "flex" }}
          className="Fabricationsmallsection2"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
            }}
            className="Fabricationtwoimagediv "
          >
            <img src={Gallery1} style={{ width: "300px" }} />
            <img
              src={GalleryImages39}
              style={{ marginTop: "5px", width: "300px", height: "200px" }}
            />
            <button
              style={{
                display: "flex",
                alignSelf: "start",
                color: "white",
                backgroundColor: "#0092cb",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
                marginTop: "5px",
                border: "1px solid black",
                textTransform: "capitalize",
              }}
              className="Buttons"
              onClick={() => {
                history.push("/Gallery");
              }}
            >
              view more
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
            }}
          >
            <img
              src={GalleryImages14}
              style={{ width: "300px", height: "200px" }}
            />
            <img
              src={gallery5}
              style={{ marginTop: "5px", width: "300px", height: "200px " }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default GallerySection
