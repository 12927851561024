import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import WhatsAppButton from "../common components/WhatsAppButton.tsx";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { GalleryVideo2 } from "../../Assets.tsx";
import "../../ants.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Carousel = ({ images, text }) => {
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useGSAP(() => {
    gsap.from(".gsapBanner" , {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".carousel__text", {
      opacity: 0,
      duration: 2,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".carousel__text ",
        scroller: "body",
        markers: false,
        start: "top 55%",
      },
    });
  });

  const handleContactClick = () => {
    window.location.href = "tel:9822664095";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:gspuneoffice@gmail.com";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);

    return () => clearInterval(interval);
  }, [images.length]);

  const webstyle = {
    carouseltextContainer: {
      background: "lightBlack",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      padding: "20px",
      borderRadius: "10px",
      color: "white",
      width: "80%",
    },

  };
  return (
    <div className="carousel gsapBanner">
      <div className="carousel__image-container gsapBanner">
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            height: "auto",
            width: "100%",
            marginTop: "85px",
          }}
          className="GalleryVideo1"
        >
          <source src={GalleryVideo2} type="video/mp4" />
        </video>
        <div className="carousel__text " style={{ textAlign: "center" }}>
          <h1
            style={{ textTransform: "capitalize", margin: "5px" }}
            className="VideoHeading"
          >
            {text}
          </h1>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                width: "80%",
                fontSize: "20px",
                textTransform: "capitalize",
              }}
              className=" HideItem"
            >
              <span style={{ textTransform: "uppercase", margin: "5px" }}>
                Gs
              </span>{" "}
              Enterprises expertly provides effective, reliable, trusted and
              innovative solutions to residential homes and commercial
              properties across the country.
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
            className=" HideItem"
          >
            <div>
              <p
                style={{ margin: "7px", fontWeight: 700 }}
                className="videoPhoneNumber"
              >
                +91 9822664095
              </p>
              <button
                className="Buttons"
                style={{
                  padding: "25px",
                  backgroundColor: "#0092cb",
                  color: "white",
                  fontSize: "20px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={handleContactClick}
              >
                <PhoneIcon style={{ fontSize: "30px", marginRight: "10px" }} />{" "}
                Call Us
              </button>
            </div>
            <div>
              <p
                style={{ margin: "7px", fontWeight: 700 }}
                className="videoPhoneNumber"
              >
                +91 8080958182
              </p>
              <WhatsAppButton phoneNumber="+918080958182" />
            </div>
            <div>
              <p
                style={{ marginBottom: "7px", fontWeight: 700 }}
                className="videoPhoneNumber"
              >
                gspuneoffice@gmail.com
              </p>

              <button
                style={{
                  padding: "20px",
                  backgroundColor: "#0092cb",
                  color: "white",
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  cursor: "pointer",
                  width: "250px",
                  justifyContent:"space-evenly"
                }}
                className="Buttons HideItem"
                onClick={handleEmailClick}
              >
                <div style={{ display: "flex"}}>
                  <EmailIcon
                    style={{ fontSize: "40px", marginRight: "10px" }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: "16px", marginBottom: "5px" }}>
                    Email Us
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
