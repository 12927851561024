import React from "react";
import Card2 from "./Card2.tsx";

const TreatmentCards = ({ items }) => {
  return (
    <div style={{...styles.container,flexWrap:"wrap"}} className="PestControlImage">
      {items.map((item, index) => (
        <Card2
          key={index}
          image={item.image}
          name={item.name}
          description={item.description}
          route={item.route} 
        />
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItem:"center",
  },
};

export default TreatmentCards;
