import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppButton from "./WhatsAppButton.tsx";
import "../../ants.css";

const CommonButton = () => {
  const handleContactClick = () => {
    window.location.href = "tel:9822664095";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:gspuneoffice@gmail.com";
  };
  return (
    <div>
      <div className="carousel__buttons">
        <div>
        <div className="carousel__phone" >

      <p style={{margin:"0"}}>+91 9822664095</p>
      </div>

        <button  className="Buttons call-button"  style={{padding:"25px",backgroundColor: "#0092cb",color:"white", fontSize:"20px",  width:"250px",display:"flex",alignItems:"center",justifyContent:"space-evenly",borderRadius:"8px",cursor:"pointer"}} onClick={handleContactClick}><PhoneIcon style={{fontSize:"30px",marginRight:"10px"}}/> Call Us</button>
        </div>
<div>

        <div className="carousel__phone" >
        <p style={{margin:"5px"}}>+91 8080958182</p>
      </div>
        <WhatsAppButton phoneNumber="+918080958182" />
      
</div>
<div>
<div className="carousel__phone" >
        <p style={{margin:"5px"}}>gspuneoffice@gmail.com</p>
      </div>

        <button
          className="Buttons contact-button"
          style={{
            padding: "20px",
            backgroundColor: "#0092cb",
            color: "white",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent:"space-evenly",
            borderRadius: "8px",
            cursor: "pointer",
            width: "250px",
          }}
          onClick={handleEmailClick}
        >
          <div style={{ display: "flex" }}>
            <EmailIcon style={{ fontSize: "40px", marginRight: "10px" }} />
          </div>
            <span style={{ fontSize: "16px", marginBottom: "5px" }}>
              Email Us
            </span>
        </button>
</div>
      </div>
    </div>
  );
};

export default CommonButton;
