import React, { useEffect, useState } from "react";
import { Footer_bg, footerlogo, hospital, logo } from "../../Assets.tsx";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import { useHistory } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const history = useHistory();
  const handleContactClick = () => {
    window.location.href = "tel:9822664095";
  };
  const openInNewTab = () => {
    window.open(
      "https://www.google.com/maps/place/GS+Enterprises/@18.4879796,73.8552274,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2c10040b21c99:0x47830287aa1e4d45!8m2!3d18.4879796!4d73.8552274!16s%2Fg%2F11w4lnyxtd?entry=ttu",
      "_blank",
      "noopener,noreferrer"
    );
  };
  const phoneNumber = "+918080958182";
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  const handleEmailClick = () => {
    window.location.href = "mailto:gspuneoffice@gmail.com";
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapFooterLogo", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".GsapFooterLogo",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapFooterSection ", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".GsapFooterSection ",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background: `url(${Footer_bg}) no-repeat center center/cover`,
      }}
    >
      <div>
        <div className="footer_bg"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "114px",
          }}
        >
          <img
            src={logo}
            style={{
              width: "300px",
              marginTop: "30px",
              // marginLeft: "50px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/");
            }}
            className="GsapFooterLogo"
          />
        </div>
        <div
          style={{ padding: "30px 50px 30px 50px" }}
          className="GsapFooterSection"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            className="FooterContainer"
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textTransform: "capitalize",
                  lineHeight: 1.5,
                }}
                className="FooterSection"
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#0092cb",
                  }}
                >
                  Our Services
                </span>
                <a
                  className="RouteOptions2"
                  href="#SlidingWindow"
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      borderBottom: "none",
                      padding: "0",
                      fontSize: "15px",
                    }}
                  >
                    Invisible Grill & Net service
                  </span>
                </a>
                <a
                  className="RouteOptions2"
                  href="#pigeonNet"
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      borderBottom: "none",
                      padding: "0",
                      fontSize: "15px",
                    }}
                  >
                    Bird Netting
                  </span>
                </a>
                <a
                  className="RouteOptions2"
                  href="#SlidingWindow"
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      borderBottom: "none",
                      padding: "0",
                      fontSize: "15px",
                    }}
                  >
                    Sliding Windows & fabrication
                  </span>
                </a>
                <a
                  href="#pestControl"
                  className="RouteOptions2"
                  style={{ color: "black", textDecoration: "none" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      borderBottom: "none",
                      padding: "0",
                      fontSize: "15px",
                    }}
                  >
                    False Ceiling & Cloth Dryer
                  </span>
                </a>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textTransform: "capitalize",
                  lineHeight: 1.5,
                  marginTop: "50px",
                }}
                className="FooterSection footerSectionMargin"
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#0092cb",
                  }}
                >
                  Pest Control Services
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/cockroach");
                  }}
                >
                  Cockroach control
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/Termite");
                  }}
                >
                  Termite control
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/BedBug");
                  }}
                >
                  bedBug control
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/mosquitos");
                  }}
                >
                  Mosquitos control
                </span>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textTransform: "capitalize",
                  lineHeight: 1.5,
                }}
                className="FooterSection"
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#0092cb",
                  }}
                >
                  sliding windows & Fabrication Services
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/AluminiumSlidingWindow");
                  }}
                >
                  Aluminium Sliding window
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/MsSecurityDoor");
                  }}
                >
                  MS security door
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/AcpPanel");
                  }}
                >
                  ACP Panel / Structure grill
                </span>
                <span
                  style={{ fontSize: "15px" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/Railling");
                  }}
                >
                  all types of grills / railling{" "}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textTransform: "capitalize",
                  lineHeight: 1.5,
                  marginTop: "30px",
                }}
                className="FooterSection footerSectionMargin"
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#0092cb",
                  }}
                >
                  other links
                </span>
                <span
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  className="RouteOptions2"
                  onClick={() => {
                    history.push("/Aboutus");
                  }}
                >
                  abouts us
                </span>
                <span
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  className="RouteOptions2"
                  onClick={handleContactClick}
                >
                  contact us
                </span>
              </div>
            </div>

            <div>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="FooterSection"
              >
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#0092cb",
                    marginBottom: "10px",
                  }}
                >
                  Location
                </span>
                <div style={{ width: "100%" }}>
                  <div className="map-container">
                    <div className="map-overlay" onClick={openInNewTab}></div>
                    <iframe
                      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAAdnoL9ZLaHUAZQ0z1QDjgQWXJCkFlCuE&q=Gopika+Bungalow,+LIC+Colony,+Taware+Colony,+Parvati+Paytha,+Pune,+Maharashtra+411009"
                      width="100%"
                      height="300px"
                      style={{ border: 0, borderRadius: "5px" }}
                      loading="lazy"
                      title="Google Map"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textTransform: "capitalize",
                lineHeight: 1.5,
              }}
              className="FooterSection "
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#0092cb",
                  margin: "0",
                }}
              >
                FOLLOW US ON
              </p>
              <div
                style={{
                  display: "flex",
                }}
              >
                <a
                  href="https://www.facebook.com/gspestcontrolpune?mibextid=qi2Omg&rdid=iu8Juv4if9obqRwR&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fkpa41VpvLGaZrbaX%2F%3Fmibextid%3Dqi2Omg"
                  className="footerSocialIcon"
                  target="_blank"
                >
                  <FacebookIcon
                    style={{ color: "#0092cb", marginRight: "10px" }}
                    className="footerSocialIcon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/gsenterprisespune/?igsh=MWViZW42cXpqODNwaQ%3D%3D"
                  className="footerSocialIcon"
                  target="_blank"
                >
                  <InstagramIcon
                    style={{
                      color: "#0092cb",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    className="footerSocialIcon"
                  />
                </a>
              </div>
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", textDecoration: "none" }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <WhatsApp style={{ color: "#00d757" }} />
                  <span style={{ marginLeft: "8px" }} className="RouteOptions2">
                    WhatsApp Us
                  </span>
                </span>
                <span
                  style={{ fontWeight: 600, cursor: "pointer" }}
                  className="RouteOptions2"
                >
                  +91 8080958182
                </span>
              </a>
              <div
                style={{ marginTop: "10px", cursor: "pointer" }}
                onClick={handleContactClick}
              >
                <span
                  style={{
                    fontWeight: 600,
                    display: "flex",
                  }}
                >
                  <CallIcon style={{ color: "#0092cb" }} />
                  <span style={{ marginLeft: "8px" }} className="RouteOptions2">
                    Call Us
                  </span>
                </span>
                <span style={{ fontWeight: 600 }} className="RouteOptions2">
                  +91 9822664095
                </span>
              </div>
              <div
                style={{ marginTop: "10px", cursor: "pointer" }}
                onClick={handleEmailClick}
              >
                <span
                  style={{
                    fontWeight: 600,
                    display: "flex",
                  }}
                >
                  <EmailIcon style={{ color: "#0092cb" }} />
                  <span style={{ marginLeft: "8px" }} className="RouteOptions2">
                    Email Us
                  </span>
                </span>
                <span style={{ fontWeight: 600 }} className="RouteOptions2">
                  {" "}
                  gspuneoffice@gmail.com{" "}
                </span>
              </div>
            </div>
          </div>
          <p style={{ fontSize: "12px", textAlign: "center", color: "gray" }}>
            2024 © GS Enterprises. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
