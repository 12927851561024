import React, { useEffect, useState } from 'react'
import TreatmentCards from '../common components/TreamentCards.tsx'
import { Grill1, Pigeon1, societyDuctNet, childrenSafetyNet, Industrialsmall, net1, TerraceNet } from '../../Assets.tsx';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const InvisibleGrill = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
        gsap.from(".InvisibleGrillGSAP h1", {
            opacity: 0,
            duration: 2,
            x: -500,
            scrollTrigger: {
              trigger: ".InvisibleGrillGSAP h1",
              scroller: "body",
              markers: false,
              start: "top 50%",
            },
          });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".InvisibleGrillGSAP .InVisibleGrillCards", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".InvisibleGrillGSAP .InVisibleGrillCards",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
    const netItem = [
        {
          image: Grill1,
          name: "Invisible grill ",
          description: " Invisible grill installation",
          route: "/Invisiblegrill",
        },
        {
          image: Pigeon1,
          name: "Pigeon net",
          description: " Pigeon net installation",
          route: "/pigeonNet",
        },
        {
          image: societyDuctNet,
          name: "society duct net",
          description: " society duct net installation",
          route: "/societyDuctNet",
        },
        {
          image: childrenSafetyNet,
          name: "Children safety net",
          description: " Children safety net installation",
          route: "/ChildrenSafetNet",
        },
    
        {
          image: Industrialsmall,
          name: "Industrial Net ",
          description: " Industrial net installation",
          route: "/IndustrialNet",
        },
        {
          image: net1,
          name: "sports net",
          description: " sports Net installation",
          route: "/SportNet",
        },
        {
          image: TerraceNet,
          name: " Terrace Net ",
          description: " Terrace Net installation",
          route: "/Terracenet",
        },
      ];
  return (
    <div
    id="pigeonNet"
    className="PestControlContainer InvisibleGrillGSAP"
    style={{ paddingTop: "100px", paddingBottom: "50px" }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#0092cb",
          textTransform: "capitalize",
          fontSize: "40px",
        }}
      >
        Invisible Grill & Net service
      </h1>
      <div className='InVisibleGrillCards'>

      <TreatmentCards items={netItem} />
      </div>
    </div>
  </div>
  )
}

export default InvisibleGrill
