import React, { useEffect, useState } from 'react'
import { ants2, BedBug, Bird2, cockroach2, Housefly, mosquitos2, mouse, SilverFish, spider2, Termite2 } from '../../Assets.tsx';
import TreatmentCards from '../common components/TreamentCards.tsx';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const PestControl = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapPestControl h1", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".GsapPestControl h1",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapPestControl .paragraphGsap", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".GsapPestControl .paragraphGsap",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  
  useGSAP(() => {
    if (!isMobile) {

    const footerSection = gsap.from(".gsapCardsSection", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapCardsSection",
        scroller: "body",
        markers: false,
        start: "top 100%",
      },
    });
}
  });


    const TreatmentItem = [
        {
          image: mouse,
          name: "rat control",
          description: " rat control treatments",
          route: "/Rats",
        },
        {
          image: mosquitos2,
          name: "Mosquitos control",
          description: " Mosquito control treatments      ",
          route: "/mosquitos",
        },
        {
          image: BedBug,
          name: "BedBug control",
          description: " BedBug control treatments      ",
          route: "/BedBug",
        },
        {
          image: Termite2,
          name: "termite control",
          description: " termite control treatments      ",
          route: "/Termite",
        },
        {
          image: cockroach2,
          name: "cockroach control",
          description: " cockroach  control treatments      ",
          route: "/cockroach",
        },
        {
          image: ants2,
          name: "ant control",
          description: " ant control treatments      ",
          route: "/ants",
        },
        {
          image: spider2,
          name: "spider control",
          description: " spider control treatments      ",
          route: "/spider",
        },
        {
          image: Housefly,
          name: "Housefly control",
          description: " Housefly control treatments      ",
          route: "/Housefly",
        },
        {
          image: SilverFish,
          name: "SilverFish control",
          description: " SilverFish control treatments      ",
          route: "/silverfish",
        },
        {
          image: Bird2,
          name: "Bird control",
          description: " Bird control treatments",
          route: "/Bird",
        },
      ];
  return (
    <div style={{ height: "auto", width: "100%" }}>
    <div
      style={{
        background: "#fafafa",
        width: "100%",
        paddingBottom: "20px",
        marginBottom: "20px",
        paddingTop: "100px",
      }}
      id="pestControl"
      className="PestControlContainer GsapPestControl"
    >
      <h1
        style={{
          textAlign: "center",
          color: "#0092cb",
          textTransform: "capitalize",
          fontSize: "40px",
        }}
      >
        pest Control Treatment for common pests
      </h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className='paragraphGsap'
      >
        <p style={{ textAlign: "justify", margin: "0" }}>
          Find out more about{" "}
          <span style={{ color: "#0092cb", marginRight: "5px" }}>
            GS Enterprises
          </span>
          solutions for common pests in homes and businesses. Contact us
          online or call us at{" "}
          <span style={{ color: "#0092cb", marginRight: "5px" }}>
            +91 9822664095
          </span>
          to avail a pest survey of your premise.
        </p>
      </div>
      <div className='gsapCardsSection'>
        <TreatmentCards items={TreatmentItem} />
      </div>
    </div>
  </div>
  )
}

export default PestControl

