import React, { useEffect, useState } from 'react'
import TreatmentCards from '../common components/TreamentCards.tsx'
import { AcpPanelSmall, AluminiumSlidingsmall, ClothDriyer, collapsibleMosquitosmall, mosquitosSlide, msSecurityDoor, pvcPanelSmall, Railsmall } from '../../Assets.tsx';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SlidingWindow = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useGSAP(() => {
        if (!isMobile) {
            gsap.from(".GsapSlidingWindowHeading", {
                opacity: 0,
                duration: 1,
                scale: 0.5,
                y: 20,
                delay: 1,
                scrollTrigger: {
                    trigger: ".GsapSlidingWindowHeading",
                    scroller: "body",
                    markers: false,
                    start: "top 55%",
                },
            });
        }
    });
    useGSAP(() => {
        if (!isMobile) {
            gsap.from(".GsapSlidingWindowSection", {
                opacity: 0,
                duration: 2,
                x: -500,
                scrollTrigger: {
                  trigger: ".GsapSlidingWindowSection",
                  scroller: "body",
                  markers: false,
                  start: "top 50%",
                },
              });
        }
      });
    const FabricationItem = [
        {
          image: AluminiumSlidingsmall,
          name: "Aluminium Sliding Window",
          description: " Aluminium Sliding Window installation",
          route: "/AluminiumSlidingWindow",
        },
        {
          image: msSecurityDoor,
          name: "Ms security door",
          description: " door installation      ",
          route: "/MsSecurityDoor",
        },
        {
          image: mosquitosSlide,
          name: " mosquitos sliding mesh",
          description: " mosquitos sliding mesh installation      ",
          route: "/MosquitosSlide",
        },
        {
          image: AcpPanelSmall,
          name: "Panel & Partition work",
          description: " Panel & Partition work installation",
          route: "/AcpPanel",
        },
        {
          image: Railsmall,
          name: "Gallery/ Terrace shade ",
          description: "Grill Installation",
          route: "/Railling",
        },
        {
          image: ClothDriyer,
          name: "cloth Dryer",
          description: " cloth dryer installation      ",
          route: "/ClothDriyer",
        },
        {
          image: collapsibleMosquitosmall,
          name: "collapsible mosquito mesh",
          description: " collapsible mosquito mesh installation",
          route: "/CollapsibleMosquito",
        },
        {
          image: pvcPanelSmall,
          name: "False Ceiling in PVC and POP",
          description: " False Ceiling in PVC and POP",
          route: "/pvcpanel",
        },
      ];
  return (
    <div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "70px 0",
      }}
      id="SlidingWindow"
      className="PestControlContainer"
    >
      <h1
        style={{
          color: "#0092cb",
          textTransform: "capitalize",
          textAlign: "center",
          fontSize: "40px",
        }}
        className='GsapSlidingWindowHeading'
      >
        Sliding Windows & fabrication
      </h1>
      <div className='GsapSlidingWindowSection'>
        <TreatmentCards items={FabricationItem} />
      </div>
    </div>
  </div>
  )
}

export default SlidingWindow
