import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { footerlogo, logo } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Navbar = () => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const openMenu = () => {
    setSidebarVisibility(!isSidebarVisible);
  };

  const history = useHistory();
  const webStyle = {
    container: {
      backgroundColor: "#0092cb",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "10px 0 0 0",
      height: "auto",
      width: "100%",
      zIndex: "1111",
    },
    MenuButtonStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "white",
      border: "none",
      color: "black",
    },
    logo: {
      height: "70px",
      cursor: "pointer",
      paddingBottom: "8px",
    },
    searchBar: {
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      border: "2px solid #be9449",
    },
    options: {
      padding: "15px",
      fontWeight: 600,
      borderBottom: "1px solid grey",
      cursor: "pointer",
    },
    optionsDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    formLogo: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      display: "flex",
      alignSelf: "center",
      marginBottom: "20px",
    },
    formDiv: {
      display: "flex",
      flexDirection: "column",
    },
    sendOtpButton: {
      color: "white",
      padding: "5px",
      background: "#565e6b",
      borderRadius: "3px",
    },
    enterOtpInput: {
      marginBottom: "10px",
      padding: "10px",
      borderRadius: "3px",
    },
    HeadingOption: {
      marginRight: "40px",
      cursor: "pointer",
      color: "white",
    },
    dropdown: {
      position: "absolute",
      backgroundColor: "white",
      border: "1px solid #ccc",
      width: "100%",
      maxHeight: "200px",
      overflowY: "auto",
      zIndex: 1000,
    },
    dropdownItem: {
      padding: "10px",
      cursor: "pointer",
      display: "flex",
      textTransform: "capitalize",
      alignItems: "center",
      fontSize: "14px",
    },
    itemImage: {
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
  };
  const handleContact = () => {
    window.location.href = "tel:9822664095";
  };
  const handleEmail = () => {
    window.location.href = "mailto:gspuneoffice@gmail.com";
  };
  const handleHomeclick = () => {
    setSidebarVisibility(false);
    history.push("/");
  };
  const handleAboutUsclick = () => {
    setSidebarVisibility(false);
    history.push("/Aboutus");
  };
  const HandleHomeCleaningClick = () => {
    setSidebarVisibility(false);
    history.push("/Homecleaning");
  };

  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionName) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === sectionName ? null : sectionName
    );
  };
  const HandleWhatsApp = () => {
    const phoneNumber = "+918080958182";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  useGSAP(() => {
    gsap.from(".gsaplogo", {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".gsapRightSideNav", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.3,
      delay: 1,
    });
  });

  return (
    <div
      className="NavbarContainer"
      style={{
        ...webStyle.container,
        position: "fixed",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "0 40px",
        }}
        className="navbarLogoContainer"
      >
        <img
          onClick={() => history.push("/")}
          src={footerlogo}
          style={webStyle.logo}
          className="logo gsaplogo"
        />
      </div>
      <Box style={{ ...webStyle.optionsDiv, padding: "0 40px" }}>
        <span
          className="items RouteOptions gsapRightSideNav"
          style={webStyle.HeadingOption}
          onClick={() => history.push("/")}
        >
          Home
        </span>
        <span
          className="items RouteOptions gsapRightSideNav"
          style={webStyle.HeadingOption}
          onClick={() => history.push("/Aboutus")}
        >
          About us
        </span>
        <div className="dropdown gsapRightSideNav">
          <span className="items RouteOptions" style={webStyle.HeadingOption}>
            Service
          </span>
          <div className="dropdown-content">
            <a
              href="#pestControl"
              className="options"
              style={webStyle.options}
              onClick={() => {
                history.push("/");
              }}
            >
              <span className="items options" style={webStyle.options}>
                Pest control
              </span>
            </a>
            <a
              onClick={() => {
                history.push("/");
              }}
              style={{
                ...webStyle.options,
                margin: "0",
                textDecoration: "none",
              }}
              href="#pigeonNet"
              className="options"
            >
              <span className="items options">Invisible Grill</span>
            </a>{" "}
            <a
              style={{ ...webStyle.options, margin: "0" }}
              href="#pigeonNet"
              className="options"
              onClick={() => {
                history.push("/");
              }}
            >
              <span className="items options">Bird Netting</span>
            </a>
            <a
              href="#SlidingWindow"
              className="options"
              style={webStyle.options}
              onClick={() => {
                history.push("/");
              }}
            >
              <span className="items options">
                Sliding Window & Fabrication
              </span>
            </a>
          </div>
        </div>
        <div className="dropdown gsapRightSideNav">
          <span className="items RouteOptions" style={webStyle.HeadingOption}>
            Contact Us
          </span>
          <div className="dropdown-content">
            <span
              onClick={handleContact}
              className="items options"
              style={webStyle.options}
            >
              Call Us
            </span>
            <span
              className="items options"
              style={webStyle.options}
              onClick={HandleWhatsApp}
            >
              Whatsapp Us{" "}
            </span>
            <span
              style={webStyle.options}
              className="items options"
              onClick={handleEmail}
            >
              Email Us
            </span>
          </div>
        </div>
      </Box>
      <Box className="item  humburger-menu">
        <MenuIcon
          onClick={openMenu}
          style={{ fontSize: "35px", marginRight: "20px" }}
        />
      </Box>
      {isSidebarVisible && (
        <Box className="sidebar">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              width: "98%",
              margin: "12px",
            }}
          >
            <img
              onClick={() => history.push("/")}
              src={logo}
              style={webStyle.logo}
              className="logo"
            />
            <CloseIcon
              onClick={() => setSidebarVisibility(false)}
              style={{ marginRight: "15px" }}
            />
          </div>

          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "2.5",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      onClick={handleHomeclick}
                      className=" RouteOptions "
                      style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "black",
                      }}
                    >
                      Home{" "}
                    </span>
                    <span
                      className=" RouteOptions "
                      style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "black",
                      }}
                      onClick={handleAboutUsclick}
                    >
                      About Us
                    </span>
                  </div>
                  <p
                    onClick={() => handleSectionClick("Service")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      justifyContent: "space-between",
                      padding: "15px",
                      border: "none",
                      margin: "0",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: 600 }}>Service</span>
                    </div>
                    <span>
                      {openSection === "Service" ? (
                        <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ fontSize: "15px" }} />
                      )}
                    </span>
                  </p>
                  {openSection === "Service" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        href="#pestControl"
                        className="options"
                        style={{
                          ...webStyle.options,
                          color: "black",
                          textDecoration: "none",
                        }}
                        onClick={handleHomeclick}
                      >
                        <span
                          className=" options"
                          style={{
                            ...webStyle.options,
                            textDecoration: "none",
                            borderBottom: "none",
                            padding: "0",
                          }}
                        >
                          Pest control
                        </span>
                      </a>
                      <a
                        href="#pigeonNet"
                        className="options"
                        style={{
                          ...webStyle.options,
                          color: "black",
                          textDecoration: "none",
                        }}
                        onClick={handleHomeclick}
                      >
                        <span
                          className=" options"
                          style={{
                            ...webStyle.options,
                            textDecoration: "none",
                            borderBottom: "none",
                            padding: "0",
                          }}
                        >
                          Invisible Grill
                        </span>
                      </a>

                      <a
                        href="#pigeonNet"
                        className="options"
                        style={{
                          ...webStyle.options,
                          color: "black",
                          textDecoration: "none",
                        }}
                        onClick={handleHomeclick}
                      >
                        <span
                          className=" options"
                          style={{
                            ...webStyle.options,
                            textDecoration: "none",
                            borderBottom: "none",
                            padding: "0",
                          }}
                        >
                          Bird Netting
                        </span>
                      </a>
                      <a
                        href="#SlidingWindow"
                        className="options"
                        style={{
                          ...webStyle.options,
                          color: "black",
                          textDecoration: "none",
                        }}
                        onClick={handleHomeclick}
                      >
                        <span
                          className=" options"
                          style={{
                            ...webStyle.options,
                            textDecoration: "none",
                            borderBottom: "none",
                            padding: "0",
                          }}
                        >
                          Sliding Window & Fabrication
                        </span>
                      </a>
                    </div>
                  )}
                  <p
                    onClick={() => handleSectionClick("Contact Us")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      justifyContent: "space-between",
                      padding: "15px",
                      border: "none",
                      borderBottom: "1px solid grey",
                      margin: "0",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: 600 }}>Contact Us</span>
                    </div>
                    <span>
                      {openSection === "Contact Us" ? (
                        <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ fontSize: "15px" }} />
                      )}
                    </span>
                  </p>
                  {openSection === "Contact Us" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        className=" options"
                        style={webStyle.options}
                        onClick={handleContact}
                      >
                        Call Us
                      </span>
                      <span
                        className=" options"
                        style={webStyle.options}
                        onClick={HandleWhatsApp}
                      >
                        Whatsapp Us
                      </span>
                      <span
                        style={webStyle.options}
                        className=" options"
                        onClick={handleEmail}
                      >
                        Email Us
                      </span>
                    </div>
                  )}{" "}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Navbar;
