import { useEffect } from 'react';
import HomePage from './component/Homepage/Homepage.tsx';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import AboutsusPage from './component/Aboutus/AboutsusPage.tsx';
import Gallery from './component/Gallery/Gallery.tsx';
import  MousePage from './component/Treatments/MousePage.tsx';
import Mosquitos from './component/Treatments/MosquitosPage.tsx';
import BedBugPage from './component/Treatments/BedBugPage.tsx';
import TermitePage from './component/Treatments/TermitePage.tsx';
import cockroachPage from './component/Treatments/cockroachpage.tsx';
import SilverFishPage from './component/Treatments/SilverFish.tsx';
import HouseFlyPage from './component/Treatments/HouseflyPage.tsx';
import antPage from './component/Treatments/AntsPage.tsx';
import spiderPage from './component/Treatments/SpiderPage.tsx';
import BirdPage from './component/Treatments/BirdPage.tsx';
import WoodBorerPage from './component/Treatments/WoodBorerPage.tsx';
import sportNet from './component/Invisble grill & Net service/SportNet.tsx';
import PigeonNet from './component/Invisble grill & Net service/PigeonNet.tsx';
import IndustrialNet from './component/Invisble grill & Net service/IndustrialNet.tsx';
import SocietyDuctNet from './component/Invisble grill & Net service/SocietyDuctNet.tsx';
import InvisibleGrillPage from './component/Invisble grill & Net service/InvisibleGrillPage.tsx';
import ChildrenNet from './component/Invisble grill & Net service/childrenNet.tsx';
import TerraceNet from './component/Invisble grill & Net service/TerraceNet.tsx';
import AluminiumSlidePage from './component/Sliding window/AluminiumSlidePage.tsx';
import MsSecurityDoorPage from './component/Sliding window/MsSecutiryDoorPage.tsx';
import CollapsibleMosquito from './component/Sliding window/CollapsibleMosquito.tsx';
import AcpPanelPage from './component/Sliding window/AcpPanelPage.tsx';
import RailingPage from './component/Sliding window/RailingPage.tsx';
import ClothDriyerPage from './component/Sliding window/ClothDriyerPage.tsx';
import MosquitoSlidePage from './component/Sliding window/MosquitosSlidePage.tsx';
import PvcPanelPage from './component/Sliding window/PvcPanelPage.tsx';



function App() {

  const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }, [history]);

    return null;
  };

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Aboutus" component={AboutsusPage} />
          <Route exact path="/Gallery" component={Gallery} />
          <Route exact path="/Rats" component={MousePage} />
          <Route exact path="/mosquitos" component={Mosquitos} />
          <Route exact path="/BedBug" component={BedBugPage} />
          <Route exact path="/Termite" component={TermitePage} />
          <Route exact path="/cockroach" component={cockroachPage} />
          <Route exact path="/silverfish" component={SilverFishPage} />
          <Route exact path="/HouseFly" component={HouseFlyPage} />
          <Route exact path="/ants" component={antPage} />
          <Route exact path="/spider" component={spiderPage} />
          <Route exact path="/Bird" component={BirdPage} />
          <Route exact path="/woodBorer" component={WoodBorerPage} />
          <Route exact path="/SportNet" component={sportNet} />
          <Route exact path="/pigeonNet" component={PigeonNet} />
          <Route exact path="/IndustrialNet" component={IndustrialNet} />
          <Route exact path="/ChildrenSafetNet" component={ChildrenNet} />
          <Route exact path="/societyDuctNet" component={SocietyDuctNet} />
          <Route exact path="/Invisiblegrill" component={InvisibleGrillPage} />
          <Route exact path="/Terracenet" component={TerraceNet} />
          <Route exact path="/AluminiumSlidingWindow" component={AluminiumSlidePage} />
          <Route exact path="/MsSecurityDoor" component={MsSecurityDoorPage} />
          <Route exact path="/CollapsibleMosquito" component={CollapsibleMosquito} />
          <Route exact path="/AcpPanel" component={AcpPanelPage} />
          <Route exact path="/Railling" component={RailingPage} />
          <Route exact path="/ClothDriyer" component={ClothDriyerPage} />
          <Route exact path="/MosquitosSlide" component={MosquitoSlidePage} />
          <Route exact path="/pvcpanel" component={PvcPanelPage} />

























        </Switch>
      </Router>

    </>
  );
}

export default App;
