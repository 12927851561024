export const HomeImage=require('./Assets/PestControlBanner.webp');
export const HomeImage2=require('./Assets/banner2.webp');
export const HomeImage3=require('./Assets/banner3.webp');
export const cockroach=require('./Assets/cockroach.png');
export const Termite=require('./Assets/termites.png');
export const rat=require('./Assets/rat1.png');
export const Bird=require('./Assets/Bird.png');
export const Mosquitos=require('./Assets/Mosquitos.png');
export const Flies=require('./Assets/flies.jpg');
export const bedBug=require('./Assets/BedBug.png');
export const spider=require('./Assets/spider.png');
export const ants=require('./Assets/ant.png');
export const logo=require('./Assets/logo.png');
export const footerlogo=require('./Assets/Footerlogo.png');
export const WhatsApp=require('./Assets/whatsapp.png');
export const Aboutus=require('./Assets/aboutsus.jpg');
export const industrial =require('./Assets/industrial.png');
export const Hotel =require('./Assets/Hotel.png');
export const Education =require('./Assets/education.png');
export const plate =require('./Assets/plate.avif');
export const hospital =require('./Assets/hospital.png');
export const retail =require('./Assets/retail.jpeg');
export const construction =require('./Assets/construction.png');
export const cartImage =require('./Assets/cart.png');
export const HappyCustomer =require('./Assets/Happy_Customers.png');
export const cities=require('./Assets/Presence_across_30_cities.png');
export const Rating =require('./Assets/Rating_2_.png');
export const Prize =require('./Assets/prize.png');
export const mouse =require('./Assets/mouse.webp');
export const mosquitos2 =require('./Assets/mosquitos2.png');
export const BedBug=require('./Assets/Bedbugs_2.webp');
export const Termite2=require('./Assets/termite2.jpg');
export const cockroach2=require('./Assets/cockroach2.webp');
export const ants2=require('./Assets/ant2.jpg');
export const lizard=require('./Assets/lizard.jpg');
export const lizardBanner=require('./Assets/lizard2.jpg');
export const spider2=require('./Assets/spider.jpeg');
export const SpiderBanner=require('./Assets/spiderBanner.webp');
export const Housefly=require('./Assets/Housefly.webp');
export const SilverFish=require('./Assets/Silverfish.webp');
export const Gallery1=require('./Assets/gallery1.jpg');
export const GalleryHero=require('./Assets/gallery3.jpg');
export const gallery3=require('./Assets/gallery4.jpg');
export const gallery4=require('./Assets/gallery5.webp');
export const gallery5=require('./Assets/gallery6.jpg');
export const GalleryVideo=require('./Assets/GalleryVideo.mp4');
export const GalleryVideo2=require('./Assets/Gallery2.mp4');
export const GalleryImages1=require('./Assets/gallery1.jpeg');
export const GalleryImages2=require('./Assets/gallery1.jpeg');
export const GalleryImages3=require('./Assets/gallery3.jpeg');
export const GalleryImages4=require('./Assets/gallery4.jpeg');
export const GalleryImages5=require('./Assets/gallery5.jpeg');
export const GalleryImages6=require('./Assets/gallery6.jpeg');
export const GalleryImages7=require('./Assets/gallery7.jpeg');
export const GalleryImages8=require('./Assets/gallery8.jpeg');
export const GalleryImages9=require('./Assets/gallery9.jpeg');
export const GalleryImages10=require('./Assets/gallery10.jpeg');
export const GalleryImages11=require('./Assets/gallery11.jpeg');
export const GalleryImages12=require('./Assets/gallery12.jpeg');
export const GalleryImages13=require('./Assets/gallery13.jpeg');
export const GalleryImages14=require('./Assets/gallery14.jpeg');
export const GalleryImages15=require('./Assets/gallery15.jpeg');
export const GalleryImages16=require('./Assets/gallery16.jpeg');
export const GalleryImages17=require('./Assets/gallery17.jpeg');
export const GalleryImages18=require('./Assets/gallery18.jpeg');
export const GalleryImages19=require('./Assets/gallery19.jpeg');
export const GalleryImages20=require('./Assets/gallery20.jpeg');
export const GalleryImages21=require('./Assets/gallery21.jpeg');
export const GalleryImages22=require('./Assets/gallery22.jpeg');
export const GalleryImages23=require('./Assets/gallery23.jpeg');
export const GalleryImages24=require('./Assets/gallery24.jpeg');
export const GalleryImages25=require('./Assets/gallery25.jpeg');
export const GalleryImages26=require('./Assets/gallery26.jpeg');
export const GalleryImages27=require('./Assets/gallery20.jpg');
export const GalleryImages28=require('./Assets/gallery21.jpg');
export const GalleryImages29=require('./Assets/22.jpg');
export const GalleryImages30=require('./Assets/23.jpg');
export const GalleryImages31=require('./Assets/24.jpg');
export const GalleryImages32=require('./Assets/25.jpg');
export const GalleryImages33=require('./Assets/26.jpg');
export const GalleryImages34=require('./Assets/27.jpg');
export const GalleryImages35=require('./Assets/28.jpg');
export const GalleryImages36=require('./Assets/29.jpg');
export const GalleryImages37=require('./Assets/30.jpg');
export const GalleryImages38=require('./Assets/31.jpg');
export const GalleryImages39=require('./Assets/32.jpg');



export const Mouse=require('./Assets/mouseBanner.webp');
export const Mosquitos3=require('./Assets/mosquitos4.webp');
export const BedBugBanner=require('./Assets/bedbugBanner.webp');
export const TermiteBanner=require('./Assets/termiteBanner.webp');
export const cockroachBanner=require('./Assets/cockroachBanner.webp');
export const silverFishBanner =require('./Assets/silverFishBanner.webp');
export const woodStood =require('./Assets/woodStod.webp');
export const Bird2 =require('./Assets/bird.webp');
export const fabrication1 =require('./Assets/fabrication1.jpg');
export const fabrication2 =require('./Assets/fabrication2.jpg');
export const fabrication3 =require('./Assets/fabrication3.webp');
export const fabrication4 =require('./Assets/fabrication4.jpg');
export const fabrication5 =require('./Assets/fabrication5.jpg');
export const Grill1 =require('./Assets/Grill1.webp');
export const Grill2 =require('./Assets/Grill2.jpg');
export const Grill3 =require('./Assets/Grill3.jpg');
export const Grill4 =require('./Assets/Grill4.webp');
export const Pigeon1 =require('./Assets/pigeon1.jpg');
export const net1 =require('./Assets/net1.jpg');
export const gardenNet =require('./Assets/gardenNet.webp');
export const FootballNet =require('./Assets/football-net.webp');
export const BirdBanner =require('./Assets/birdTreatment.jpg');
export const woodBorer =require('./Assets/wood borer.png');
export const HouseflyBanner=require('./Assets/HouseflyBanner.webp');
export const cricketNet =require('./Assets/cricketnet.jpg');
export const FootballNet2 =require('./Assets/footballnet1.png');
export const childrenSafetyNet =require('./Assets/childrenSafetynet.png');
export const societyDuctNet =require('./Assets/societyDuctNet.jpg');
export const SlidingWindow =require('./Assets/SlidingWindow.jpg');
export const InvisiblegrillBanner =require('./Assets/InvisibleGrillBanner.jpg');
export const childrenSafetyNetBanner =require('./Assets/ChildrenSafetyNetBanner.jpg');
export const HomeCleaningBanner =require('./Assets/homecleaning.jpg');
export const TerraceNetBanner =require('./Assets/TerraceNet.jpg');
export const IndustrailBanner=require('./Assets/IndustrialBig.jpg');
export const AntBanner=require('./Assets/ant3.jpg');
export const PigeonBanner=require('./Assets/pigeon1Banner.jpg');
export const Banner=require('./Assets/pigeon1Banner.jpg');
export const SocietyDuctNetBanner=require('./Assets/societyBanner.webp');
export const Industrialsmall=require('./Assets/industrial1.jpg');
export const TerraceNet=require('./Assets/TerraceNetSmall.jpg');
export const GardenNetBanner=require('./Assets/GardenNetBanner.png');
export const AluminiumSlidingsmall=require('./Assets/Alumininium2.jpg');
export const AluminiumSlidingBanner=require('./Assets/aluminiumBanner.jpg');
export const msSecurityDoor=require('./Assets/SecurityDoorsmall.jpg');
export const SecurityDoorBanner=require('./Assets/securitydoorBanner.jpg');
export const collapsibleMosquitosmall=require('./Assets/collapsiblemosquitosmall.jpg');
export const collapsibleMosquitoBanner=require('./Assets/mosquitoCollapsible.jpg');
export const AcpPanelSmall=require('./Assets/acp-panel-small.jpg');
export const AcpPanelBanner=require('./Assets/acp-panel-banner.webp');
export const Railsmall=require('./Assets/railling-small.jpg');
export const RailingBanner=require('./Assets/Railling-banner.jpg');
export const ClothDriyer=require('./Assets/cloth driyer.webp');
export const ClothDriyerBanner=require('./Assets/cloth-Dryier-Banner.webp');
export const mosquitosSlide=require('./Assets/mosquitosSlide.jpg');
export const mosquitosSlideBanner=require('./Assets/mosquitosSlide-banner.jpg');
export const pvcPanelSmall=require('./Assets/pvc-panel-small.webp');
export const pvcBanner=require('./Assets/pvc-banner.jpg');
export const kakaHalwai=require('./Assets/kakaHalwai.webp');
export const wipro=require('./Assets/Wipro.png');
export const sarwataTech=require('./Assets/Sarwarta technologies.png');
export const mumbai=require('./Assets/mumbai.gif');
export const milk=require('./Assets/milk.png');
export const rio=require('./Assets/Rio.PNG');
export const Regus=require('./Assets/Regus.jpg');
export const Jp=require('./Assets/JP.png');
export const Shangrila=require('./Assets/Shangrila.png');
export const COEP=require('./Assets/COEP.png');
export const CC=require('./Assets/Cc.png');
export const Axis=require('./Assets/Axis Bank.png');
export const male=require('../src/Assets/male.JPG');
export const female=require('../src/Assets/female.JPG');
export const Atum=require('../src/Assets/Atum.png');
export const Aditya=require('../src/Assets/aditya.webp');
export const Baro=require('../src/Assets/Baro_Logo.png');
export const Forest=require('../src/Assets/forest3.png');
export const mahabaleshwar=require('../src/Assets/mahabaleshwar.jpg');
export const Axiverse=require('../src/Assets/axiverse.png');
export const Footer_bg=require('../src/Assets/Footer_bg.jpeg');



















































































