import React from "react";
import Navbar from "../Navbar/Navbar.tsx";
import Footer from "../Footer/Footer.tsx";
import { SocietyDuctNetBanner, societyDuctNet } from "../../Assets.tsx";
import "../../ants.css";
import CommonButton from "../common components/CommonButton.tsx";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SocietyDuctNet = () => {
  const history =useHistory();

  useGSAP(() => {
    gsap.from(".gsapBannerSociety" , {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".carousel__text", {
      opacity: 0,
      duration: 2,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".carousel__text ",
        scroller: "body",
        markers: false,
        start: "top 55%",
      },
    });
  });


  return (
    <div>
      <Navbar />
      <div style={{ position: "relative" }}>
        <img
        src={SocietyDuctNetBanner}
          style={{ width: "100%",height:"auto" }}
          className="BannerImage gsapBannerSociety"
        />
        <div className="carousel__text">
        <a
            href="#pigeonNet"
            className="options"
            style={{ color: "black", textDecoration: "none" }}
            onClick={() => {
              history.push("/");
            }}
          >
            <button
              style={{
                display: "flex",
                alignSelf: "start",
                color: "white",
                background: "#0092cb",
                border: "none",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              className="Buttons"
            >
              Back
            </button>
          </a>
          <div className="carousel__text-container">
            <p style={{ textTransform: "capitalize" }}>
              for society duct Net installation connect us
            </p>
          </div>
         <CommonButton />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SocietyDuctNet;
